import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Auth = Loader(lazy(() => import('src/screens/auth/Login')));

const AdminCompany = Loader(
  lazy(() => import('src/screens/pages/Admin/Company'))
);
const AdminUsers = Loader(lazy(() => import('src/screens/pages/Admin/Users')));
const UserMenu = Loader(lazy(() => import('src/screens/pages/Admin/UserMenu')));
const Forget = Loader(lazy(() => import('src/screens/auth/Forget')));

const AdminCountry = Loader(
  lazy(() => import('src/screens/pages/Admin/Country'))
);
const AdminState = Loader(lazy(() => import('src/screens/pages/Admin/State')));
const AdminModes = Loader(lazy(() => import('src/screens/pages/Admin/Mode')));
const CRSettings = Loader(
  lazy(() => import('src/screens/pages/Admin/CRSettings'))
);

const CRCurrency = Loader(
  lazy(() => import('src/screens/pages/Masters/CRCurrency'))
);
const CRInvestor = Loader(
  lazy(() => import('src/screens/pages/Masters/CRInvestor'))
);
const CRCustomer = Loader(
  lazy(() => import('src/screens/pages/Masters/CRCustomer'))
);
const CRACHead = Loader(
  lazy(() => import('src/screens/pages/Masters/CRACHead'))
);
const CRPurchase = Loader(
  lazy(() => import('src/screens/pages/Transaction/CRPurchase'))
);
const CRSales = Loader(
  lazy(() => import('src/screens/pages/Transaction/CRSales'))
);
const CRReceipts = Loader(
  lazy(() => import('src/screens/pages/Transaction/CRReceipt'))
);
const CRPayments = Loader(
  lazy(() => import('src/screens/pages/Transaction/CRPayment'))
);
const CRExpenses = Loader(
  lazy(() => import('src/screens/pages/Transaction/CRExpenses'))
);
const CRIncomes = Loader(
  lazy(() => import('src/screens/pages/Transaction/CRIncomes'))
);

const CRLedger = Loader(
  lazy(() => import('src/screens/pages/Reports/CRLedger'))
);
const CRCustLedger = Loader(
  lazy(() => import('src/screens/pages/Reports/CRCustLedger'))
);
const CRPnL = Loader(lazy(() => import('src/screens/pages/Reports/CRPnL')));
const CRInvLedger = Loader(
  lazy(() => import('src/screens/pages/Reports/CRInvLedger'))
);
const CRExpLedger = Loader(
  lazy(() => import('src/screens/pages/Reports/CRExpLedger'))
);

// Status
const Status404 = Loader(
  lazy(() => import('src/screens/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/screens/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/screens/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/screens/pages/Status/Maintenance'))
);

const ChangePassword = Loader(
  lazy(() => import('src/screens/management/profile/ChangePassword'))
);
const Profile = Loader(
  lazy(() => import('src/screens/management/profile/Profile'))
);

const Crypto = Loader(lazy(() => import('src/screens/dashboards/Crypto')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Crypto />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      }
    ]
  },
  {
    path: 'admin',
    element: <SidebarLayout />,
    children: [
      {
        path: 'company',
        element: <AdminCompany />
      },
      {
        path: 'users',
        element: <AdminUsers />
      },
      {
        path: 'usermenu',
        element: <UserMenu />
      },
      {
        path: 'country',
        element: <AdminCountry />
      },
      {
        path: 'state',
        element: <AdminState />
      },
      {
        path: 'mode',
        element: <AdminModes />
      },
      {
        path: 'settings',
        element: <CRSettings />
      }
    ]
  },
  {
    path: 'masters',
    element: <SidebarLayout />,
    children: [
      {
        path: 'currency',
        element: <CRCurrency />
      },
      {
        path: 'customers',
        element: <CRCustomer />
      },
      {
        path: 'acheads',
        element: <CRACHead />
      },
      {
        path: 'investor',
        element: <CRInvestor />
      }
    ]
  },
  {
    path: 'transaction',
    element: <SidebarLayout />,
    children: [
      {
        path: 'purchase',
        element: <CRPurchase />
      },
      {
        path: 'purchase/:new',
        element: <CRPurchase />
      },
      {
        path: 'sales',
        element: <CRSales />
      },
      {
        path: 'sales/:new',
        element: <CRSales />
      }
    ]
  },
  {
    path: 'entry',
    element: <SidebarLayout />,
    children: [
      {
        path: 'receipts',
        element: <CRReceipts />
      },
      {
        path: 'receipts/:new',
        element: <CRReceipts />
      },
      {
        path: 'payments',
        element: <CRPayments />
      },
      {
        path: 'payments/:new',
        element: <CRPayments />
      },
      {
        path: 'expenses',
        element: <CRExpenses />
      },
      {
        path: 'expenses/:new',
        element: <CRExpenses />
      },
      {
        path: 'incomes',
        element: <CRIncomes />
      },
      {
        path: 'incomes/:new',
        element: <CRIncomes />
      }
    ]
  },
  {
    path: 'reports',
    element: <SidebarLayout />,
    children: [
      {
        path: 'ledger',
        element: <CRLedger />
      },
      {
        path: 'custledger',
        element: <CRCustLedger />
      },
      {
        path: 'pnl',
        element: <CRPnL />
      },
      {
        path: 'investor',
        element: <CRInvLedger />
      },
      {
        path: 'expense',
        element: <CRExpLedger />
      }
    ]
  },
  {
    path: 'management/profile',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Profile />
      },
      {
        path: 'changepassword',
        element: <ChangePassword />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

const authroutes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Auth />
      },
      {
        path: 'company',
        element: <AdminCompany />
      },
      {
        path: 'forget',
        element: <Forget />
      }
    ]
  },
  {
    path: '*',
    element: <Auth />
  }
];

export default routes;
export { authroutes };
