import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../redux/store';
import React, { useState } from 'react';
import moment from 'moment-timezone';

const apiLink =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3000/api/'
    : 'https://currencypos-fqdvdzgvh8awcuhh.centralindia-01.azurewebsites.net/api/';
// : 'https://currencypos-api.vercel.app/api/';

const GlobalContext = React.createContext({});
export const GlobalProvider = GlobalContext.Provider;
export default GlobalContext;

export const logoPath =
  'https://bluehmsfiles.s3.ap-south-1.amazonaws.com/logo/';

export const btBaseQuery = fetchBaseQuery({
  baseUrl: apiLink,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).gdata.token;

    if (token) {
      headers.set('auth', `${token}`);
    }
    return headers;
  }
});

export const storeToken = (jwtToken: string) => {
  try {
    localStorage.setItem('token', jwtToken);
  } catch (error) {
    // Error saving data
  }
};

export const retrieveToken = () => {
  try {
    const value = localStorage.getItem('token');
    if (value !== null) {
      return value;
    }
  } catch (error) {
    return error;
  }
};

export const storeSetting = (name: string, value: string) => {
  try {
    localStorage.setItem(name, value);
  } catch (error) {
    // Error saving data
  }
};

export const retrieveSetting = (name: string) => {
  try {
    const value = localStorage.getItem(name);
    if (value !== null) {
      return value;
    }
  } catch (error) {
    return error;
  }
};

export const removeSetting = (name: string) => {
  try {
    localStorage.removeItem(name);
    return true;
  } catch (error) {
    return error;
  }
};

export const modeType = [
  { id: '0', label: 'Credit' },
  { id: '1', label: 'Cash' },
  { id: '2', label: 'Card' },
  { id: '3', label: 'Cheque' },
  { id: '4', label: 'Bank Transfer' },
  { id: '5', label: 'UPI' },
  { id: '6', label: 'Complimentary' },
  { id: '7', label: 'Void' }
];

export const useAsyncState = (initialState: any) => {
  const [state, setState] = useState(initialState);

  const asyncSetState = (value: any) => {
    return new Promise((resolve) => {
      setState(value);
      setState((current: any) => {
        resolve(current);
        return current;
      });
    });
  };

  return [state, asyncSetState];
};

export const toUTCDate = (locdt: any) => {
  var localTime = moment(locdt).utc().format('YYYY-MM-DD HH:MM:SS');

  return localTime;
};

var a = [
  '',
  'one ',
  'two ',
  'three ',
  'four ',
  'five ',
  'six ',
  'seven ',
  'eight ',
  'nine ',
  'ten ',
  'eleven ',
  'twelve ',
  'thirteen ',
  'fourteen ',
  'fifteen ',
  'sixteen ',
  'seventeen ',
  'eighteen ',
  'nineteen '
];
var b = [
  '',
  '',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety'
];

export const inWords = (num) => {
  if ((num = num.toString()).length > 9) return 'overflow';
  let n: any = ('000000000' + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = '';
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore '
      : '';
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh '
      : '';
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand '
      : '';
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred '
      : '';
  str +=
    n[5] != 0
      ? (str != '' ? 'and ' : '') +
        (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) +
        'only '
      : 'only';
  return str.toUpperCase();
};
